<template>
  <div class="input-container">

    <div class="label-container">
      <FormLabel
        v-if="showLabel"
        :formName="formName"
        :fieldName="fieldName"
      />
      <slot name="label-extra-content" />
    </div>

    <FormLabelDescription
      :formName="formName"
      :fieldName="fieldName"
      v-if="field.labelDescription"
    />

    <div class="grouped-button-container">

      <div class="icon-overlay" :class="{ 'password': isPasswordField }">

        <SearchIcon class="search-icon" :class="[size]" v-if="field.type === 'search'" />

        <component
          class="toggle-password-icon"
          v-if="field.type === 'password'"
          @click="togglePasswordVisibility"
          :is="isPasswordShown ? 'EyeSlashIcon' : 'EyeIcon'"
        />

        <CheckCircleIcon
          class="validation-icon text-success-500"
          v-if="showValidationIcons && field.isDirty && !field.error"
        />
        <ErrorIcon
          class="validation-icon text-danger-600"
          v-if="showValidationIcons && field.isDirty && field.error"
        />

        <textarea
          v-if="field.type === 'textarea'"
          rows="4"
          :id="uuid"
          @blur="onBlur"
          @keyup="onKeyUp"
          ref="formElement"
          :type="field.type"
          :class="fieldClasses"
          v-model="field.value"
          @keypress="onKeyPress"
          :disabled="field.disabled"
          :required="field.required"
          :placeholder="field.placeholder"
        />

        <textarea
          v-else-if="field.type === 'text' && field.metaType === 'multi'"
          :id="uuid"
          @blur="onBlur"
          @keyup="onKeyUp"
          ref="formElement"
          :type="field.type"
          class="pseudo-input"
          @keydown="onKeyDown"
          :class="fieldClasses"
          @keypress="onKeyPress"
          :disabled="field.disabled"
          :required="field.required"
          v-model="field.displayValue"
          :placeholder="field.placeholder"
        />

        <input
          v-else
          :id="uuid"
          @blur="onBlur"
          @keyup="onKeyUp"
          ref="formElement"
          :class="fieldClasses"
          v-model="field.value"
          @keypress="onKeyPress"
          :disabled="field.disabled"
          :required="field.required"
          :placeholder="field.placeholder"
          :type="field.type !== 'password' ? field.type : (isPasswordShown ? 'text' : 'password')"
        />

      </div>

      <button
        type="button"
        class="grouped-button"
        :disabled="isMultiButtonDisabled"
        @click.prevent="addMultiValues()"
        v-if="field.type === 'text' && field.metaType === 'multi'"
      >
        <AddIcon />
      </button>

    </div>

    <FormNote
      v-if="field.note"
      :formName="formName"
      :fieldName="fieldName"
    />

    <FormError
      v-if="showError"
      :formName="formName"
      :fieldName="fieldName"
    />

    <div class="tag-container" v-if="field.type === 'text' && field.metaType === 'multi'">
      <div class="mb-6" v-if="field.invalidMultiValues.length !== 0">
        <p class="mb-2 font-bold text-lg text-danger-700">Invalid (not added)</p>
        <div :key="value" v-for="value in field.invalidMultiValues">
          <Tag variant="danger" size="normal" :clickable="true" :monospace="true" @click="removeInvalidMultiValue(value)">
            {{ value }}
          </Tag>
        </div>
      </div>
      <div v-if="field.value.length !== 0">
        <p class="mb-2 font-bold text-lg"  v-if="field.invalidMultiValues.length !== 0">Added</p>
        <div :key="value" v-for="value in field.value">
          <Tag variant="dark-gray" size="normal" :clickable="true" :monospace="true" @click="removeMultiValue(value)">
            {{ value }}
          </Tag>
        </div>
      </div>
    </div>

  </div>

</template>

<script>

  import { mapState } from 'vuex'
  import { ref, toRefs } from 'vue'

  import useFilters from '@/composables/useFilters'
  import useFormField from '@/composables/useFormField'

  import Tag from '@/components/utils/Tag.vue'
  import FormNote from '@/components/forms/FormNote.vue'
  import FormError from '@/components/forms/FormError.vue'
  import FormLabel from '@/components/forms/FormLabel.vue'
  import FormLabelDescription from '@/components/forms/FormLabelDescription.vue'

  import EyeIcon from '@/assets/icons/eye.svg'
  import AddIcon from '@/assets/icons/add.svg'
  import ErrorIcon from '@/assets/icons/error.svg'
  import SearchIcon from '@/assets/icons/search.svg'
  import EyeSlashIcon from '@/assets/icons/eye-slash.svg'
  import CheckCircleIcon from '@/assets/icons/check-circle.svg'

  export default {
    emits: ['keypress', 'keydown'],
    components: {
      Tag,
      EyeIcon,
      AddIcon,
      FormNote,
      FormError,
      FormLabel,
      ErrorIcon,
      SearchIcon,
      EyeSlashIcon,
      CheckCircleIcon,
      FormLabelDescription,
    },
    props: {
      formName: {
        type: String,
        required: true,
      },
      fieldName: {
        type: String,
        required: true,
      },
      size: {
        type: String,
        default: null,
        validator: (value) => {
          return value === 'small'
        },
      },
      showLabel: {
        type: Boolean,
        default: true,
      },
      showError: {
        type: Boolean,
        default: true,
      },
      showValidationIcons: {
        type: Boolean,
        default: true,
      },
    },
    setup(props) {

      const isPasswordShown = ref(false)

      const { formatNumber, formatNumberAsUSD } = useFilters()
      const formFieldComposables = useFormField(toRefs(props))

      return Object.assign({
        formatNumber,
        isPasswordShown,
        formatNumberAsUSD,
      }, formFieldComposables)

    },
    computed: {
      ...mapState('app', ['decimalSeparator']),
      isPasswordField() {
        return this.field.type === 'password' || this.isPasswordShown
      },
      fieldClasses() {
        return [
          {
            password: this.isPasswordField,
            'has-icon': this.field.type === 'search',
            error: this.field.isDirty && !!this.field.error,
          },
          ...[this.size],
        ]
      },
      isMultiButtonDisabled() {

        if (this.field.disabled || !this.field.displayValue) {
          return true
        }

        if (typeof this.field.isAtLeastOneMultiEntryValid !== 'function') {
          return false
        }

        return !this.field.isAtLeastOneMultiEntryValid(this.field.displayValue)

      },
    },
    watch: {
      // when the displayValue changes for multi inputs, replace any newlines
      //  with commas, and also replace multiple commas with just one
      //
      // this "cleans up" poorly formatted lists when someone pastes a long list
      //  of items
      'field.displayValue': function displayValue(newValue, oldValue) {

        // for multi-inputs only
        if (this.field.metaType !== 'multi') return

        if (newValue.includes(',') || newValue.includes('\n')) {
          this.$store.commit('forms/SET_FIELD_DISPLAY_VALUE', {
            fieldName: this.fieldName,
            formName: this.formName,
            newValue: newValue.replace(/\n+/g, ',').replace(/,{2,}/g, ','),
          })
        }

      },
    },
    created() {
      // call onKeyUp() on creation to apply any currency / number formatting
      //  that may need to happen if this field is pre-set with a value
      this.onKeyUp()
    },
    methods: {
      onKeyPress($event) {
        this.$emit('keypress', $event)
      },
      onKeyDown($event) {
        // for multi-inputs only, add new values when the enter key is pressed
        if (this.field.metaType !== 'multi') return
        if ($event.code !== 'Enter') return
        $event.preventDefault()
        this.addMultiValues()
      },
      onKeyUp() {

        const oldValue = typeof this.field.displayValue === 'string'
          ? this.field.displayValue
          : this.field.value

        if (oldValue === '') return

        let newValue = oldValue

        if (this.field.metaType === 'currency') {
          newValue = this.formatNumberAsUSD(oldValue)

        } else if (this.field.metaType === 'formatted-number') {
          newValue = this.formatNumber(oldValue, undefined, null, false)

        } else if (typeof this.field.format === 'function') {
          newValue = this.field.format(oldValue)

        } else {
          return
        }

        if (oldValue === newValue) return

        if (typeof this.field.displayValue === 'string') {
          this.$store.commit('forms/SET_FIELD_DISPLAY_VALUE', {
            fieldName: this.fieldName,
            formName: this.formName,
            newValue,
          })

        } else {
          this.$store.commit('forms/SET_FIELD_VALUE', {
            fieldName: this.fieldName,
            formName: this.formName,
            newValue,
          })
        }

        // sometimes auto-fill can interfere with inputs in a weird way, so just
        //  abandon trying to restore the cursor if we have no reference to the
        //  input
        if (!this.formElement) return

        const oldLength = oldValue.length
        const oldCursorPosition = this.formElement.selectionStart

        const newLength = newValue.length
        const newCursorPosition = newLength - oldLength + oldCursorPosition

        this.$nextTick(() => {
          this.formElement.setSelectionRange(newCursorPosition, newCursorPosition)
        })

      },
      onBlur() {

        const value = typeof this.field.displayValue === 'string'
          ? this.field.displayValue
          : this.field.value

        if (value === '') return

        let newValue = value

        if (this.field.metaType === 'currency') {

          const decimalPosition = value.indexOf(this.decimalSeparator)

          if (decimalPosition === -1) {
            newValue += `${this.decimalSeparator}00`

          } else {

            const rightSide = value.substr(decimalPosition + 1, 2)

            if (rightSide.length === 0) {
              newValue += '00'
            } else if (rightSide.length === 1) {
              newValue += '0'
            }

          }

        } else if (this.field.metaType === 'formatted-number') {
          newValue = this.formatNumber(value)

        } else if (typeof this.field.format === 'function') {
          newValue = this.field.format(value)

        } else {
          return
        }

        if (typeof this.field.displayValue === 'string') {
          this.$store.commit('forms/SET_FIELD_DISPLAY_VALUE', {
            fieldName: this.fieldName,
            formName: this.formName,
            newValue,
          })

        } else {
          this.$store.commit('forms/SET_FIELD_VALUE', {
            fieldName: this.fieldName,
            formName: this.formName,
            newValue,
          })
        }

      },
      removeInvalidMultiValue(valueToRemove) {

        // for multi-inputs only, remove a value from the "invalid values" list
        if (this.field.metaType !== 'multi') return

        const newValue = this.field.invalidMultiValues
          .filter((value) => {
            return value !== valueToRemove
          })

        this.$store.commit('forms/SET_FIELD_INVALID_MULTI_VALUES', {
          fieldName: this.fieldName,
          formName: this.formName,
          newValue,
        })

      },
      removeMultiValue(valueToRemove) {

        // for multi-inputs only, remove a value from the "valid values" list
        if (this.field.metaType !== 'multi') return

        const newValue = this.field.value
          .filter((value) => {
            return value !== valueToRemove
          })

        this.$store.commit('forms/SET_FIELD_VALUE', {
          fieldName: this.fieldName,
          formName: this.formName,
          newValue,
        })

      },
      addMultiValues() {

        // for multi-inputs only, add all new values that are currently in the
        //  textarea (i.e. the field's displayValue)
        if (this.field.metaType !== 'multi') return

        if (this.isMultiButtonDisabled) return

        const newValues = new Set(this.field.value)
        const invalidMultiValues = new Set(this.field.invalidMultiValues)

        const displayValues = this.field.displayValue
          .replaceAll('\n', ',')
          .replaceAll(' ', '')
          .split(',')
          .filter((value) => {
            return value !== ''
          })

        this.$store.commit('forms/SET_FIELD_DISPLAY_VALUE', {
          fieldName: this.fieldName,
          formName: this.formName,
          newValue: '',
        })

        if (displayValues.length === 0) return

        displayValues.forEach((displayValue) => {

          const displayValueError = this.$store.getters['forms/getFieldError'](this.formName, this.fieldName, displayValue)

          if (displayValueError === null) {
            newValues.add(
              typeof this.field.format === 'function'
                ? this.field.format(displayValue)
                : displayValue
            )
          } else {
            invalidMultiValues.add(displayValue)
          }

        })

        this.$store.dispatch('forms/UPDATE_FIELD', {
          formName: this.formName,
          fieldName: this.fieldName,

          newIsDirty: false,
          shouldValidate: false,
          newValue: Array.from(newValues),
          newInvalidMultiValues: Array.from(invalidMultiValues),
        })

      },
      togglePasswordVisibility() {
        if (this.field.type !== 'password') return
        this.isPasswordShown = !this.isPasswordShown
      },
    },
  }

</script>

<style lang="stylus" scoped>

  textarea.pseudo-input
    @apply h-12
    @apply resize-none
    @apply rounded-tr-none
    @apply rounded-br-none
    @apply overflow-y-hidden

  .grouped-button-container
    @apply flex
    @apply w-full

    .grouped-button
      @apply p-2
      @apply w-12
      @apply rounded-tl-none
      @apply rounded-bl-none

      svg
        @apply w-6
        @apply h-6

</style>
