<template>
  <span
    class="tag"
    :tabindex="clickable ? 0 : null"
    :class="[{ 'is-clickable': clickable, 'font-mono': monospace }, ...[variant, size]]"
  >
    <span class="tag-inner">
      <span class="label">
        <slot />
      </span>
      <span title="remove" class="close" v-if="clickable">
        <CloseIcon />
      </span>
    </span>
  </span>
</template>

<script>

  import CloseIcon from '@/assets/icons/close.svg'

  export default {
    props: {
      clickable: {
        type: Boolean,
        default: false,
      },
      monospace: {
        type: Boolean,
        default: false,
      },
      size: {
        type: String,
        default: 'small',
        validator: (value) => {
          return value === 'small' || value === 'normal'
        },
      },
      variant: {
        type: String,
        default: 'gray',
        validator: (value) => {
          return value === 'gray' || value === 'dark-gray' || value === 'danger' || value === 'purple' || value === 'cyan'
        },
      },
    },
    components: {
      CloseIcon,
    },
  }

</script>

<style lang="stylus">

  // @NOTE: styles imported globally in main.js

</style>
